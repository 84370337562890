import { render, staticRenderFns } from "./AboutUs.vue?vue&type=template&id=a1613832&scoped=true&"
import script from "./AboutUs.vue?vue&type=script&lang=js&"
export * from "./AboutUs.vue?vue&type=script&lang=js&"
import style0 from "./AboutUs.vue?vue&type=style&index=0&id=a1613832&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_vue-template-co_r7gorhlsuse2clbgb7n6qowp3u/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1613832",
  null
  
)

export default component.exports